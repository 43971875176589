
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import CreateUnitStepFourSection from '@/components/sections/dashboard/property/CreateUnitStepFourSection.vue'
import Main from '@/templates/Main.vue'

export default defineComponent({
  name: 'CreateUnitStepFour',
  components: {
    Main,
    CreateUnitStepFourSection
  },
  setup () {
    const { t } = useI18n({ useScope: 'global' })
    useMeta({
      title: t('message.title.building_create_2s')
    })
  }
})
